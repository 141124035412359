import styled from "styled-components"

export const AllProjectsWrapper = styled.div`
  height: auto;
  min-height: 100vh;
  /* width: auto; */
  width: auto;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
`
